<template>
  <div class="container" v-cloak>
    <!-- 您的贷款申请已提交 -->
    <div class="top-con u-flex-col u-col-center">
      <img src="../assets/images/img_submitted.png" />
      <h4>您的贷款申请已提交</h4>
      <p>贷款额度：{{ loanAmount }}元</p>
    </div>

    <!-- 请前往APP确认您的贷款申请 -->
    <p class="u-flex u-row-center tips">
      <img src="../assets/images/icon_tips.png" />
      <span>请前往APP确认您的贷款申请</span>
    </p>

    <!-- 融树客专属客服 -->
    <div class="btn u-text-center" v-if="countDown" @click="handleDownload">
      {{ `立即下载APP (${countDown}s)` }}
    </div>

    <div class="btn u-text-center" @click="handleDownload" v-else>
      立即下载APP
    </div>

    <!-- 贷款流程 -->
    <div class="loan-process-con">
      <h4>贷款流程</h4>
      <ul class="u-flex u-text-center">
        <li class="loan-process-li u-flex-1">
          <img src="../assets/images/icon_fkxtsh1.png" />
          <h6>风控系统审核</h6>
          <div class="u-flex u-row-center">
            <img class="icon-img" src="../assets/images/icon_check_mark.png" />
            <span class="green">已通过</span>
          </div>
          <p class="kfk-p">可放款</p>
        </li>
        <li class="line pr">
          <img class="pa" src="../assets/images/tl4_step_line.png" />
        </li>
        <li class="loan-process-li u-flex-1">
          <img src="../assets/images/icon_appxz.png" />
          <h6>下载APP</h6>
          <div class="u-flex u-row-center">
            <img class="icon-img" src="../assets/images/icon_jxz.png" />
            <span class="yellow">进行中</span>
          </div>
          <p @click="handleDownload">立即下载</p>
        </li>
        <li class="line pr">
          <img class="pa" src="../assets/images/tl4_step_line.png" />
        </li>
        <li class="loan-process-li u-flex-1">
          <img src="../assets/images/icon_appqk1.png" />
          <h6>APP取款</h6>
          <div class="u-flex u-row-center">
            <img class="icon-img" src="../assets/images/icon_dddhfs.png" />
            <span class="default">等待电话复审</span>
          </div>
          <p @click="handleDownload">立即下载</p>
        </li>
      </ul>
    </div>

    <!-- 热线电话 -->
    <p class="tel-con u-text-center">
      <van-icon size="0.22rem" color="#E71A0F" name="phone-o" />
      <a href="tel:4001131190">客服热线: 400-1030-615</a>
    </p>
    <!-- 底部 -->
    <div class="bottom u-text-center">
      服务由深上海赢燚网络科技有限公司提供<br />
      综合年化6%-24%，最终结果以审批为准<br />
      贷款有风险，借款需谨慎<br />
      请根据个人能力合理贷款，理性消费
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "TrialSuccessTl5",
  data() {
    return {
      source: this.$route.query.source, // 渠道标识
      loanAmount: this.$route.query.loanAmount, // 金额
      showPop: false, // 分享蒙层状态
      countDown: 5, // 倒计时5秒
      timer: null, // 计时器
    };
  },
  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }

    this.getDownloadUrl();

    this.timer = setInterval(() => {
      this.countDown--;
      if (this.countDown === 0) {
        clearInterval(this.timer);
        this.handleDownload();
      }
    }, 1000);
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          setTimeout(() => {
            window.location.href = this.downAppUrl;
          }, 50);
        }
      }
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.source, true).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f5f6fa;
  padding: 0 0.4rem 0.8rem;

  .top-con {
    background: #384ed0;
    border-radius: 0 0 0.72rem 0.72rem;
    padding: 0.78rem 0 0.74rem;
    margin: 0 -0.4rem;
    color: #fff;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: normal;

    > img {
      width: 2.4rem;
      height: 2.4rem;
    }

    > h4 {
      font-size: 0.32rem;
      font-family: PingFang SC, PingFang SC-Medium;
      margin: 0.34rem 0 0.16rem;
    }

    > p {
      font-size: 0.28rem;
      padding: 0.1rem 0.16rem;
      border-radius: 0.06rem;
      display: inline-block;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .tips {
    font-size: 0.28rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: normal;
    color: #fb9401;
    padding: 0.14rem 0;
    background: rgba(251, 148, 1, 0.1);
    border-radius: 0.34rem;
    margin: 0.3rem 0;

    > img {
      width: 0.3rem;
      margin-right: 0.04rem;
    }
  }

  .btn {
    font-size: 0.28rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: normal;
    height: 0.96rem;
    line-height: 0.96rem;
    background: #384ed0;
    border-radius: 0.48rem;
    color: #ffffff;
  }

  .loan-process-con {
    margin-top: 0.48rem;
    background: #ffffff;
    border-radius: 0.24rem;
    font-size: 0.24rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: normal;
    padding: 0.3rem 0.3rem 0.24rem;

    > h4 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
      color: #192337;
    }

    ul {
      align-items: stretch;
      margin-top: 0.28rem;
    }

    .loan-process-li {
      > img {
        width: 0.72rem;
        height: 0.72rem;
        border-radius: 50%;
      }

      .icon-img {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        margin-right: 0.06rem;
      }

      h6 {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #192337;
        margin: 0.2rem 0 0.08rem;
      }

      span.green {
        color: #52c41a;
      }

      span.yellow {
        color: #f5a623;
      }

      span.default {
        color: #868e9e;
      }

      p {
        margin-top: 0.08rem;
        color: #384ed0;

        &.kfk-p {
          color: #868e9e;
        }
      }
    }

    .line {
      width: 0;
      padding-top: 0.3rem;
      > img {
        width: 0.84rem;
        height: 0.08rem;
        right: -0.4rem;
        top: 0.3rem;
      }
    }
  }

  .bottom {
    margin: 1rem auto 0;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 0.22rem;
    color: #c3c2c6;
    line-height: 0.36rem;
    white-space: pre-line;
  }
}
</style>
