var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"top-con u-flex-col u-col-center"},[_c('img',{attrs:{"src":require("../assets/images/img_submitted.png")}}),_c('h4',[_vm._v("您的贷款申请已提交")]),_c('p',[_vm._v("贷款额度："+_vm._s(_vm.loanAmount)+"元")])]),_vm._m(0),(_vm.countDown)?_c('div',{staticClass:"btn u-text-center",on:{"click":_vm.handleDownload}},[_vm._v(" "+_vm._s(`立即下载APP (${_vm.countDown}s)`)+" ")]):_c('div',{staticClass:"btn u-text-center",on:{"click":_vm.handleDownload}},[_vm._v(" 立即下载APP ")]),_c('div',{staticClass:"loan-process-con"},[_c('h4',[_vm._v("贷款流程")]),_c('ul',{staticClass:"u-flex u-text-center"},[_vm._m(1),_vm._m(2),_c('li',{staticClass:"loan-process-li u-flex-1"},[_c('img',{attrs:{"src":require("../assets/images/icon_appxz.png")}}),_c('h6',[_vm._v("下载APP")]),_vm._m(3),_c('p',{on:{"click":_vm.handleDownload}},[_vm._v("立即下载")])]),_vm._m(4),_c('li',{staticClass:"loan-process-li u-flex-1"},[_c('img',{attrs:{"src":require("../assets/images/icon_appqk1.png")}}),_c('h6',[_vm._v("APP取款")]),_vm._m(5),_c('p',{on:{"click":_vm.handleDownload}},[_vm._v("立即下载")])])])]),_c('p',{staticClass:"tel-con u-text-center"},[_c('van-icon',{attrs:{"size":"0.22rem","color":"#E71A0F","name":"phone-o"}}),_c('a',{attrs:{"href":"tel:4001131190"}},[_vm._v("客服热线: 400-1030-615")])],1),_vm._m(6),_c('SharePop',{attrs:{"show":_vm.showPop},on:{"change":_vm.handlePopChange}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"u-flex u-row-center tips"},[_c('img',{attrs:{"src":require("../assets/images/icon_tips.png")}}),_c('span',[_vm._v("请前往APP确认您的贷款申请")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"loan-process-li u-flex-1"},[_c('img',{attrs:{"src":require("../assets/images/icon_fkxtsh1.png")}}),_c('h6',[_vm._v("风控系统审核")]),_c('div',{staticClass:"u-flex u-row-center"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("../assets/images/icon_check_mark.png")}}),_c('span',{staticClass:"green"},[_vm._v("已通过")])]),_c('p',{staticClass:"kfk-p"},[_vm._v("可放款")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"line pr"},[_c('img',{staticClass:"pa",attrs:{"src":require("../assets/images/tl4_step_line.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-flex u-row-center"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("../assets/images/icon_jxz.png")}}),_c('span',{staticClass:"yellow"},[_vm._v("进行中")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"line pr"},[_c('img',{staticClass:"pa",attrs:{"src":require("../assets/images/tl4_step_line.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-flex u-row-center"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("../assets/images/icon_dddhfs.png")}}),_c('span',{staticClass:"default"},[_vm._v("等待电话复审")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom u-text-center"},[_vm._v(" 服务由深上海赢燚网络科技有限公司提供"),_c('br'),_vm._v(" 综合年化6%-24%，最终结果以审批为准"),_c('br'),_vm._v(" 贷款有风险，借款需谨慎"),_c('br'),_vm._v(" 请根据个人能力合理贷款，理性消费 ")])
}]

export { render, staticRenderFns }